import styled from 'styled-components'

export const Accordions = styled.div` 
  margin-bottom: 30px;
  
`
export const Accordionsitems = styled.div`
  position:relative;
  overflow:hidden;
  &.active{
    opacity:1;
    .accordions-title{
      background-color:rgba(255,255,255,0.3);
    }
  }
  + .accordions-items{
    margin-top:20px;
    @media(min-width:1200px){
      margin-top:30px;
    }
  }
  &.active{
    span{
      transform:rotate(135deg);
      &:before,
      &:after{
        background:#fff;
      }
    }
    .accordions-content{
      max-height:1000px;
      overflow:inherit;
      transition: max-height 0.3s ease-out;
    }
    &:before{
      opacity:1;
    }
  }
`
export const AccordionsContent = styled.div`
  max-height:0;
  overflow:hidden;
  transition: max-height .35s ease;
  position: relative;
  border-radius:0 0 6px 6px;
  border-top: 1px solid #516c7c;
  
`
export const AccordionsContentinner = styled.div`
  background-color:rgba(255,255,255,0.8);
  border-top:none;
  padding:15px 20px;
  @media (min-width: 768px) {
    padding:20px 30px;
  }
`
export const AccordionsTitle = styled.div`  
  background-color:${props=>props.bgColor};
  cursor: pointer;
  display:inline-flex;
  align-items:center;
  justify-content:space-between;
  position:relative;
  z-index:1;
  color: #fff;
  font-family: 'Red Hat Display', sans-serif;
  font-weight: 600;
  font-size: 16px;
  line-height: 28px;
  padding:5px 15px;
  border-radius:6px 6px 0 0;
  overflow:hidden;
  @media (min-width: 768px) {
    padding:8px 20px;
  }
  @media (min-width: 992px) {
    font-size: 18px;
    line-height: 30px;    
  }

  span{
    width:30px; 
    min-width: 30px;
    height:30px; 
    position:relative;
    display:inline-block;
    transition: all .35s ease;
    margin-left:30px;
    &:before,
    &:after{
      display:block;
      content:'';
      position:absolute;
      left:50%;
      top:50%;
      transform:translate(-50%, -50%);
      background:#fff;
    }
    &:before{
      width:2px;
      height:50%;
    }
    &:after{
      width:50%;
      height:2px;
    }
  }
`

AccordionsTitle.defaultProps = {
	bgColor: "rgba(255,255,255,0.3)"
}
import React, { Fragment, useEffect, useRef, useState } from "react"
import styled from "styled-components"
import SearchIcon from "../Icons/SearchIcon"
import { navigate } from "gatsby"
import { DropSearch, DropSearchItem } from "../Section"
import { useSearch } from "../../utils/useSearch"
import { getProductUrl } from "../../utils/product"
import CloseIcon from "../Icons/CloseIcon"

const SearchForm = styled.div`
  position: relative;
  background-color: #eef8ff;
  border-radius: 6px;
  width: 100%;
  form {
    position: relative;
    margin-bottom: 2px;

    input {
      width: 100%;
      height: 36px;
      font-size: 16px;
      border: 1px solid #dce7ef;
      border-radius: 6px;
      padding: 2px 5px;
      &::placeholder {
        color: #004485;
      }
      &:focus,
      &:active {
        &::placeholder {
          color: #004485;
        }
      }
    }

    button {
      position: absolute;
      border: 0;
      outline: 0;
      background: none;
      right: 10px;
      top: 5px;
      display: block;

      svg {
        fill: gray;
      }
    }
  }
`

const SearchBtn = styled.div`
  margin: 0 10px;
`

const SearchContainer = styled.div`
  position: absolute;
  top: -70px;
  left: 0;
  right: 0;
  height: 0;
  z-index: 999;
  padding: 0 40px 0 16px;
  background: #ffffffee;
  height: 70px;
  transition: top 0.2s ease-in-out;
  display: flex;
  align-items: center;

  button.btn-close {
    position: absolute;
    right: 8px;
    top: 28px;

    svg {
      fill: gray;
      width: 12px;
      height: 12px;
    }
  }

  &.show-search-box {
    top: 0;
  }
`

const MobileSearchBox = () => {
  const [isVisibleMobileSearchBar, setIsVisibleMobileSearchBar] = useState(
    false
  )
  const [searchKey, setSearchKey] = useState("")
  const results = useSearch(searchKey, "", true)
  const [showSearchDropDown, setShowSearchDropDown] = useState(false)
  const searchInputRef = useRef()
  const targetRef = useRef(null)

  const handleClickOutside = event => {
    if (targetRef.current && !targetRef.current.contains(event.target)) {
      if (event.target.id !== searchInputRef.current.id) {
        setShowSearchDropDown(false)
      }
    }
  }

  useEffect(() => {
    document.addEventListener("click", handleClickOutside)
    return () => {
      document.removeEventListener("click", handleClickOutside)
    }
  }, [])

  const handleChange = e => {
    e.preventDefault()
    setSearchKey(e.target.value)
  }

  const handleSubmit = e => {
    e.preventDefault()

    navigate("/search", { state: { searchKey: searchKey } })
  }

  const getLinkUrl = item => {
    if (item.type === "tag") {
      navigate("/shop-metal-buildings/", {
        state: { tag: item.title },
      })
    } else {
      const url = getProductUrl(item)
      navigate(`${url}/`)
    }
  }

  const getSearchItemTitle = item => {
    if (item.type === "tag") return item.title
    if (searchKey === item.skuNumber.toString()) return item.skuNumber
    return item.title
  }

  return (
    <Fragment>
      <SearchBtn
        onClick={() => setIsVisibleMobileSearchBar(true)}
        className="nav-icon"
      >
        <SearchIcon fill="#062C44" />
      </SearchBtn>
      <SearchContainer
        className={isVisibleMobileSearchBar ? "show-search-box" : ""}
      >
        <SearchForm className="search-form">
          <form onSubmit={handleSubmit}>
            <input
              ref={searchInputRef}
              className="form-control"
              type="text"
              placeholder="Search..."
              id="search"
              name="search"
              autoComplete="off"
              onFocus={() => setShowSearchDropDown(true)}
              onChange={e => handleChange(e)}
              onKeyDown={event => {
                if (event.key === "Enter") {
                  setShowSearchDropDown(false)
                  navigate("/search", { state: { searchKey: searchKey } })
                }
              }}
            />
          </form>
          {showSearchDropDown && (
            <DropSearch className="drop-search" ref={targetRef}>
              {results.map((rItem, index) => (
                <DropSearchItem
                  className="drop-search-item"
                  key={index}
                  onClick={() => getLinkUrl(rItem)}
                >
                  {getSearchItemTitle(rItem)}
                </DropSearchItem>
              ))}
            </DropSearch>
          )}
        </SearchForm>
        <button
          type="button"
          aria-label="Submit the search"
          className="btn-close"
          onClick={() => setIsVisibleMobileSearchBar(false)}
        >
          <CloseIcon />
        </button>
      </SearchContainer>
    </Fragment>
  )
}

export default MobileSearchBox

import React from "react"

const CompareIcon = props => {
  return (
    <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M15.2929 0.29289C14.9024 0.683421 14.9024 1.31658 15.2929 1.70711L16.5858 3H11C8.79091 3 7.00001 4.79087 7.00001 7.00001V8.00001C7.00001 8.55231 7.44773 9.00001 8.00001 9.00001C8.55231 9.00001 9.00001 8.55231 9.00001 8.00001V7.00001C9.00001 5.89544 9.89541 5.00001 11 5.00001H16.5858L15.2929 6.29291C14.9024 6.68341 14.9024 7.31661 15.2929 7.70711C15.6834 8.09761 16.3166 8.09761 16.7071 7.70711L19.7071 4.70712C20.0976 4.31659 20.0976 3.68343 19.7071 3.2929L16.7071 0.29289C16.3166 -0.0976301 15.6834 -0.0976301 15.2929 0.29289ZM12 7.00001C11.4477 7.00001 11 7.44771 11 8.00001V9.00001C11 10.1046 10.1046 11 9.00001 11H3.41421L4.70712 9.70711C5.09764 9.31661 5.09764 8.68341 4.70712 8.29291C4.31659 7.90241 3.68342 7.90241 3.29289 8.29291L0.29289 11.2929C-0.0976301 11.6834 -0.0976301 12.3166 0.29289 12.7071L3.29289 15.7071C3.68342 16.0976 4.31659 16.0976 4.70712 15.7071C5.09764 15.3166 5.09764 14.6834 4.70712 14.2929L3.41421 13H9.00001C11.2091 13 13 11.2091 13 9.00001V8.00001C13 7.44771 12.5523 7.00001 12 7.00001Z" fill={props.fill} />
    </svg>

  )
}

export default CompareIcon

import React from "react"
import styled from "styled-components"
import { BaseButton } from "../Section"

const PrimaryBtn = styled(BaseButton)`
  color: #fff;
  border-color: #000;
  &:before {
    border-top-color: #1e4156;
  }
  &:after {
    border-bottom-color: #062c44;
  }
  & .icon {
    > svg {
      fill: #fff;
    }
  }
  &:focus,
  &:hover {
    background: #062c44;
  }
`
const PrimaryButton = props => {
  const { icon, iconAfter, text, size } = props
  return (
    <PrimaryBtn className={`btn ${size ? `btn-${size}` : ""}`}>
      {iconAfter ? (
        <>
          {text && <span className="text">{text}</span>}
          {iconAfter && <span className="icon">{iconAfter}</span>}
        </>
      ) : (
        <>
          {icon && <span className="icon">{icon}</span>}
          {text && <span className="text">{text}</span>}
        </>
      )}
    </PrimaryBtn>
  )
}

export default PrimaryButton
